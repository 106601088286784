<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-text-field
          :value="(e.parent || { parent: {} }).name"
          label="Turnfest"
          disabled
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          :value="e.startDate | dateformat"
          label="Beginn"
          disabled
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          :value="modus(e)"
          label="Begegnungsmodus"
          disabled
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          :value="gender(e)"
          label="Geschlecht"
          disabled
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          :value="status(e)"
          label="Begegnungsstatus"
          disabled
        />
      </v-col>
      <v-col cols="12">
        <base-edit-dialog
          :value="e.publicComment"
          :id="id"
          label="öffentliche Bemerkungen"
          textarea
          @input="({value, id}) => savecomment(id, value)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useCalc } from '../../../plugins/calc'
import gql from 'graphql-tag'
import { useGraphQL } from '@/plugins/graphql'

export default {
  name: 'wettkampfdaten',

  setup (props, context) {
    return {
      ...useCalc(context),
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  methods: {
    savecomment (id, value) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $publiccomment: String!) {
            StbLtf2024TcPubliccommentUpdate(id: $id, publicComment: $publiccomment) {
              _id
            }
          }
        `,
        variables: {
          id: this.id,
          publiccomment: value
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
